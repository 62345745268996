var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"actions-center"},[_c('campaign-info-popup',{ref:"campaignMediaPopup",attrs:{"campaign":_vm.selectedCampaign}}),_c('change-campaign-start-date-popup',{ref:"changeCampaignStartDatePopup",attrs:{"campaign":_vm.selectedCampaign}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"color":"grey darken-1"},on:{"click":_vm.showCampaignMediaPopup}},on),[_vm._v(" visibility ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('open'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"data-review-campaign":"","small":"","color":"grey darken-1"},on:{"click":_vm.reviewCampaign}},on),[_vm._v(" fact_check ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('review'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"upload-media-section"},on),[_c('change-campaign-media',{staticClass:"mx-1",attrs:{"campaign":_vm.campaignRow},on:{"update:campaign":function($event){_vm.campaignRow=$event}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v(" cloud_upload ")])]},proxy:true}],null,true)})],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('change_media'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-1",attrs:{"color":"grey darken-1"},on:{"click":_vm.showChangeCampaignStartDatePopup}},on),[_vm._v(" date_range ")])]}}])},[_vm._v(" Change start date ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }