












import { Component, Vue, Prop } from 'vue-property-decorator';
import { Campaign } from '@/app/shared/utilities/static-types';
import CampaignItemContent from '@userApp/shared/components/CampaignItemContent.vue';
import ClickableListItem from '@userApp/shared/components/ClickableListItem.vue';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import namespace from '@/app/shared/store/namespace';

@Component({
  components: {
    CampaignItemContent,
    ClickableListItem,
  },
})
export default class ClickableCampaignItem extends Vue {
  @Prop({ required: true }) public campaign!: Campaign;
  @Prop({ required: false, default: false }) public isCampaignOwner!: boolean;

  public reviewCampaignRoute = AdminRoutes.ADVERTISER_CAMPAIGN_REVIEW;

  public reviewCampaign(event: MouseEvent): void {
    const input = event.target as HTMLInputElement;
    if (input.nodeName.toLowerCase() !== 'input') {
      this.setCampaignRef();
      this.$router.push({ ...this.reviewCampaignRoute });
    }
  }

  private get campaignRef() {
    const { ADVERTISER_UID, ID } = this.campaign;
    return FirebaseAppFirestore
      .collection(firebaseNames.CAMPAIGNS.VAL)
      .doc(ADVERTISER_UID)
      .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
      .doc(ID);
  }

  private setCampaignRef() {
    this.$store.commit(
      `${namespace.CampaignModule}/setCurrentStoreCampaignRef`,
      this.campaignRef,
    );
  }
}
