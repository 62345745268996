






































































import { Component, Vue, Prop } from 'vue-property-decorator';
import CampaignInfoPopup from '@campaigns/shared/components/CampaignInfoPopup.vue';
import ChangeCampaignMedia from '@campaigns/shared/components/ChangeCampaignMedia.vue';
import { Campaign, CampaignTableRow, Nullable } from '@/app/shared/utilities/static-types';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import namespace from '@/app/shared/store/namespace';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import ChangeCampaignStartDatePopup from '@/app/screens/App/screens/Campaigns/shared/components/ChangeCampaignStartDatePopup.vue';

@Component({
  components: {
    CampaignInfoPopup,
    ChangeCampaignMedia,
    ChangeCampaignStartDatePopup,
  },
})
export default class TableActions extends Vue {
  @Prop({ required: true, default: () => ({}) }) public campaignRow!: CampaignTableRow;
  @Prop({ required: true, default: false }) public isCampaignOwner!: boolean;

  public advertiserReviewCampaignRoute = AdminRoutes.ADVERTISER_CAMPAIGN_REVIEW;
  public selectedCampaign: Nullable<Campaign> = null;

  public get isCampaignPendingPayment(): boolean {
    return this.campaignRow.STATUS.VAL === SYSTEM_STATUS.PENDING_PAYMENT.VAL;
  }

  public showCampaignMediaPopup(): void {
    this.selectedCampaign = this.campaignRow;
    (this.$refs.campaignMediaPopup as CampaignInfoPopup).open();
  }

  public reviewCampaign(id: string): void {
    this.setCampaignRef();
    this.$router.push({ ...this.advertiserReviewCampaignRoute, params: { id } });
  }

  public showChangeCampaignStartDatePopup(): void {
    this.selectedCampaign = this.campaignRow;
    (this.$refs.changeCampaignStartDatePopup as ChangeCampaignStartDatePopup).open();
  }

  private get campaignRef() {
    const { ADVERTISER_UID, ID } = this.campaignRow;
    return FirebaseAppFirestore
      .collection(firebaseNames.CAMPAIGNS.VAL)
      .doc(ADVERTISER_UID)
      .collection(firebaseNames.CAMPAIGNS.USER_CAMPAIGNS)
      .doc(ID);
  }

  private setCampaignRef() {
    this.$store.commit(
      `${namespace.CampaignModule}/setCurrentStoreCampaignRef`,
      this.campaignRef,
    );
  }
}
