



























import { Component, Vue } from 'vue-property-decorator';
import Campaigns from '@campaigns/shared/components/Campaigns.vue';
import { loadPendingAdminApprovalCampaignsAction } from '../../shared/actions';
import TableActions from './components/TableActions.vue';
import ClickableCampaignItem from './components/ClickableCampaignItem.vue';
import { AuthorizedUser, Campaign, CampaignTuple } from '@/app/shared/utilities/static-types';
import { getUserById } from '@adminRoles/shared/actions';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { isCampaignOwner } from '@/app/screens/App/screens/Campaigns/shared/utils';

@Component({
  components: {
    Campaigns,
    TableActions,
    ClickableCampaignItem,
  },
})
export default class PendingAdminApprovalCampaigns extends Vue {
  public currentUser: AuthorizedUser|undefined = undefined;
  public campaignsTuples: CampaignTuple[] = [];

  public async created() {
    this.currentUser = await this.loadCurrentUser();
    this.campaignsTuples = await loadPendingAdminApprovalCampaignsAction();
  }

  public async loadCurrentUser() {
    const { uid } = (await getCurrentUser())!;
    return getUserById(uid);
  }

  public isCampaignOwner(campaign: Campaign) {
    return isCampaignOwner(campaign)(this.currentUser!);
  }
}
